import React from 'react';
import CopyRight from "./CopyRight";
import FooterItems from "./FooterItems";

function Footer({copyright, footerLinks}) {
    return (
        <div className="sm:max-w-screen-xl w-full px-5 mx-auto bg-blue-1a1a73 text-white font-proxima-nova-bold text-lg uppercase sm:p-6 p-5">
           <div className="flex lg:flex-row flex-col-reverse lg:items-center items-start justify-between">
               <CopyRight copyright={copyright} />
               <FooterItems footerLinks={footerLinks} />
           </div>
        </div>
    );
}

export default Footer;