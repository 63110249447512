import liveStreamingSection from '../../assets/images/live-streaming-bg.png';
import iconLiveStreaming from '../../assets/icons/icon-live-sign.svg';

function LiveStreamingSection() {
    return (
        <div className="bg-cover w-full relative"
             style={{background: `url(${liveStreamingSection}) no-repeat left center`, height: 700}}>
            <div className="container lg:py-24 lg:px-36 px-4 z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="divider sm-divider-margin">
                    <img src={iconLiveStreaming} alt="Sign LiveStreaming Icon"/>
                </div>
                <div className="live-stream-text-border">
                    <div className="sm:py-28 py-10">
                        <p className="font-sans sm:text-4xl text-3xl sm:text-center text-left uppercase lg:px-0 px-4 mb-4">
                            KeyShow a créé une première
                        </p>
                        <p className="font-sans sm:text-4xl text-3xl sm:text-center text-left uppercase lg:px-0 px-4 mb-4">
                            <span className="bg-white text-primary-purple">“Live Streaming”</span> du Maroc
                        </p>
                        <p className="font-sans sm:text-4xl text-3xl sm:text-center text-left uppercase lg:px-0 px-4 mb-4">
                            aux standards internationaux !
                        </p>
                        <p className="font-sans sm:text-4xl text-3xl sm:text-center text-left uppercase lg:px-0 px-4">
                            en réalisant les premiers <br/>
                        </p>
                    </div>
                </div>
                <div className="divider lg-divider-margin">
                    <button className="bg-primary-purple sm:py-8 py-4 sm:px-24 px-4 uppercase sm:text-4xl text-2xl whitespace-nowrap">en savoir plus
                    </button>
                </div>
            </div>
        </div>

    );
}

export default LiveStreamingSection;