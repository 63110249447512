import iconPlayVideo from '../assets/icons/icon-play-video.svg';
import iconLights from '../assets/icons/icon-lights.svg';
import iconLamp from '../assets/icons/icon-lamp.svg';
import iconChairUmbr from '../assets/icons/icon-chair-umbrella.svg';
import iconCamera from '../assets/icons/icon-camera.svg';
import iconProduction from '../assets/icons/icon-producation.svg';
import iconLedScreen from '../assets/icons/icon-led-screen.svg';
import iconLedScreen3d from '../assets/icons/icon-led-screen-3d.svg';
import iconPostProd from '../assets/icons/icon-post-production.svg';
import iconCatering from '../assets/icons/icon-catering.svg';
import iconLiveStreaming from '../assets/icons/icon-live-streaming.svg';
import iconSound from '../assets/icons/icon-sounds.svg';

export const getIcons = [iconPlayVideo, iconLights, iconLamp, iconChairUmbr, iconCamera, iconProduction, iconLedScreen, iconLedScreen3d, iconPostProd, iconCatering, iconLiveStreaming, iconSound];