import React from 'react';

function FooterItems({footerLinks}) {
    return (
        <ul className="flex sm:items-center items-start sm:flex-row flex-col gap-4 text-sm sm:my-0 my-5">
            {footerLinks.map((item, index) =>
                <a rel="noreferrer" href={item.link} target="_blank">
                    <li key={index}>{item.label}</li>
                </a>)}
        </ul>
    );
}

export default FooterItems;