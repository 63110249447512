import * as React from "react";
import HeroSection from "./components/heroSection/HeroSection";
import HeroVideoSection from "./components/heroVideoSection/HeroVideoSection";
import VideosSection from "./components/backstage/VideosSection";
import FaqSection from "./components/faq/FaqSection";
import GetIntouch from "./components/getIntouch/GetIntouch";
import Footer from "./components/Footer/Footer";
import {useEffect, useState} from "react";
import axios from "axios";
import OurClient from "./components/ourClient/OurClient";
import Newsletter from "./components/newsletter/Newsletter";
import MarketingSolutionsSection from "./components/marketingSolutionsSection/MarketingSolutionsSection";
import GoodDealSection from "./components/GoodDealSection";
import MarketingEssentialsSection from "./components/marketingEssentials/MarketingEssentialsSection";

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PackagesSection from "./components/packagesSection/PackagesSection";
import LiveStreamingSection from "./components/liveStreamingSection/LiveStreamingSection";
import {Helmet} from "react-helmet";
import {initGoogleTagManager} from "./services/tracking";

function App() {
    const [data, setData] = useState(null);

    useEffect(() => {
        initGoogleTagManager();

        const fetchData = async () => {
            const configPath = `/${process.env.REACT_APP_CAMPAIGN}/config.json`;
            const response = await axios.get(configPath);
            setData(response.data);
        }
        fetchData()
    }, []);


    if (data === null) {
        return <p>loading...</p>;
    }

    return (
        <>
            <Helmet>
                <title>{data.headTitle}</title>
                <meta name="description"
                      content={data.metaDescription}/>
                {/*<script>{gtm}</script>*/}
                <link rel="shortcut icon" href={data.favicon}/>
            </Helmet>
            <HeroSection heroSection={data.heroSection}/>
            {Object.entries(data.heroVideoSection).length !== 0 &&
                <HeroVideoSection heroVideoSection={data.heroVideoSection}/>
            }
            {Object.entries(data.goodDealSection).length !== 0 &&
                <GoodDealSection goodDealSection={data.goodDealSection}/>
            }
            {Object.entries(data.liveStreamingSection).length !== 0 &&
                <LiveStreamingSection/>
            }
            {Object.entries(data.packagesSection).length !== 0 &&
                <PackagesSection/>
            }
            {Object.entries(data.marketingSolutionsSection).length !== 0 &&
                <MarketingSolutionsSection marketingSolutionsSection={data.marketingSolutionsSection}/>
            }
            {Object.entries(data.marketingEssentialsSection).length !== 0 &&
                <MarketingEssentialsSection marketingEssentialsSection={data.marketingEssentialsSection}/>
            }
            {Object.entries(data.newsletter).length !== 0 &&
                <Newsletter newsletter={data.newsletter}/>
            }
            {Object.entries(data.videosSection).length !== 0 &&
                <VideosSection videosSection={data.videosSection}/>
            }
            {Object.entries(data.faqSection).length !== 0 &&
                <FaqSection/>
            }
            {Object.entries(data.ourClient).length !== 0 &&
                <OurClient ourClient={data.ourClient}/>
            }
            {Object.entries(data.getIntouch).length !== 0 &&
                <GetIntouch title={data.getIntouch.title} subtitle={data.getIntouch.subtitle}/>
            }
            <Footer copyright={data.footer} footerLinks={data.footer.footerLinks}/>
            <ToastContainer/>
        </>
    );
}

export default App;
