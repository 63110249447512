import React from 'react';
import GetIntouchHeading from "./GetIntouchHeading";
import RightPopUpForm from "../mui/utils/Drawer";

function GetIntouch({title, subtitle}) {
    return (
        <div className="bg-black">
            <div className="container py-14 xl:px-0 px-4 flex sm:flex-row flex-col sm:justify-around justify-start sm:items-center items-start">
                <GetIntouchHeading title={title} subtitle={subtitle} />
                <RightPopUpForm
                    id="get__in__touch__btn"
                    classes="text-black text-sm font-proxima-nova-bold bg-white rounded uppercase p-4 sm:mt-0 mt-4"
                    buttonLabel="contactez-nous" />
            </div>
        </div>

    );
}

export default GetIntouch;