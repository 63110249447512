import cardBg from '../../assets/images/ashnoo/card-bg.svg';
import RightPopUpForm from "../mui/utils/Drawer";

function MarketingSolutionsSection({marketingSolutionsSection}) {
    return (
        <div className="sm:max-w-screen-xl w-full sm:px-0 px-5 mx-auto">
            <div className="flex sm:flex-row flex-col flex-wrap lg:flex-nowrap items-center justify-center gap-x-24 my-10 px-5 mx-auto">
                <div>
                    <h1 className="uppercase sm:text-6xl text-3xl font-proxima-nova-bold">{marketingSolutionsSection.heading.title}</h1>
                    <p className="text-lg font-proxima-nova-regular py-5">{marketingSolutionsSection.heading.subTitle}</p>
                </div>

                <img src={marketingSolutionsSection.illustration.src}
                     alt={marketingSolutionsSection.illustration.alt}/>
            </div>
            <div className="flex flex-row justify-center items-center flex-wrap sm:gap-4 gap-9 mb-14 px-5">
                {marketingSolutionsSection.cards.map((card, index) =>
                    <div
                        className="sm:p-28 py-20 px-4 flex items-center justify-center flex-col sm:w-96 w-full sm:h-96 h-full text-blue-1a1a73 "
                        key={index}
                        style={{background: `url(${cardBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'top center'}}>
                        <img className="w-28" src={card.icon} alt=""/>
                        <h2 className="text-4xl uppercase font-proxima-nova-bold my-4">{card.title}</h2>
                        <p className="text-lg font-proxima-nova-regular text-center">{card.description}</p>
                    </div>
                )}
            </div>
            <RightPopUpForm
                id="marketing__solutions__btn"
                classes="btn py-8 sm:px-32 px-auto bg-black block sm:w-full max-w-max my-10 sm:text-3xl text-lg max-w-screen-lg mx-auto"
                buttonLabel={marketingSolutionsSection.button.label}/>

        </div>
    );
}

export default MarketingSolutionsSection;