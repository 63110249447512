import React, {useState} from 'react';
import PackagesHeading from "./packagesHeading";
import PackagesTabSection from "./PackagesTabSection";
import PackagesCardSection from "./PackagesCardSection";

function TabsContainer() {
    const [pack, setPack] = useState(null);
    return (
        <div className="container">
            <PackagesHeading/>
            <PackagesTabSection setPack={setPack} pack={pack}/>
            <div className="tabs-inner-container">
                <PackagesCardSection pack={pack}/>
            </div>
        </div>
    );
}

export default TabsContainer;