import RightPopUpForm from "./mui/utils/Drawer";

function GoodDealSection({goodDealSection}) {

    return (
        <div className="bg-cover w-full relative sm:py-0 py-xl sm:px-5"
             style={{background: `#c1f84f no-repeat left center`, height: 600}}>
            <div
                className="sm:max-w-screen-2xl w-full px-5 mx-auto lg:px-36 px-4 z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="divider sm-divider-margin sm:-mt-[19px] -mt-0">
                    <h1 className="lg:text-4xl sm:text-3xl text-base text-blue-1a1a73 font-proxima-nova-bold uppercase sm:px-0 px-5"> {goodDealSection.title}
                    </h1>
                </div>
                <div className="live-stream-text-border">
                    <div className="text-blue-1a1a73 sm:-mt-[21px] mt-1">
                        <p className="max-w-screen-sm mx-auto sm:text-lg text-sm text-center text-blue-1a1a73 font-proxima-nova-bold uppercase py-8 sm:px-0 px-4 sm:-mt-0 -mt-4">
                            {goodDealSection.subTitle}
                        </p>
                        <div
                            className="sm:grid flex sm:grid-cols-5 flex-col flex-wrap sm:gap-4 gap-0 mb-10px-10 align-baseline">
                            {goodDealSection.icons.map((icon, index) =>
                                <div className="flex items-center justify-center flex-col sm:pb-20 pb-5" key={index}>
                                    <img className="xl:w-auto lg:w-14 sm:w-12 w-10" src={icon.src} alt={icon.alt}/>
                                    <h3 className="text-lg my-4 text-center sm:px-0 px-10">{icon.title}</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="divider lg-divider-margin ">
                    <RightPopUpForm
                        id="good__deal__btn"
                        classes="bg-blue-1a1a73 sm:py-8 py-4 sm:px-10 px-4 uppercase sm:text-4xl text-2xl whitespace-nowrap"
                        buttonLabel="Programmer un rdv"/>
                </div>
            </div>
        </div>

    );
}

export default GoodDealSection;