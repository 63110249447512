import Collapsible from "react-collapsible";

function FaqAccordion() {
    return (
        <div>
            <Collapsible trigger="Dans quelle ville le tournage se déroule ?">
                <div className="font-proxima-nova-regular px-8 pb-4 cursor-auto">
                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    <p> Aperiam aspernatur, aut error est ex</p>
                    <p> necessitatibus quas quasi qui rerum unde?</p>
                </div>
            </Collapsible>
            <Collapsible trigger="Dombien de personnes peuvent intervenir ?">
                <div className="font-proxima-nova-regular px-8 pb-4 cursor-auto">
                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    <p> Aperiam aspernatur, aut error est ex</p>
                    <p> necessitatibus quas quasi qui rerum unde?</p>
                </div>
            </Collapsible>
            <Collapsible trigger="Est-ce que la vidéo est diffusée en Live ?">
                <div className="font-proxima-nova-regular px-8 pb-4 cursor-auto">
                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    <p> Aperiam aspernatur, aut error est ex</p>
                    <p> necessitatibus quas quasi qui rerum unde?</p>
                </div>
            </Collapsible>
            <Collapsible trigger="Est-ce qu’on peut changer la configuration des écrans LED ?">
                <div className="font-proxima-nova-regular px-8 pb-4 cursor-auto">
                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    <p> Aperiam aspernatur, aut error est ex</p>
                    <p> necessitatibus quas quasi qui rerum unde?</p>
                </div>
            </Collapsible>
        </div>
    );
}

export default FaqAccordion;