import Slider from "react-slick";

function OurClient({ourClient}) {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };
    return (
        <Slider {...settings}>
            {ourClient.logo.map(c =>
                <div className="container my-10">
                    <img key={c.alt} src={c.src} alt={c.alt}/>
                </div>
            )}
        </Slider>
    );
}

export default OurClient;