import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import {ContactForm} from "../../forms/ContactForm";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    drawer: {
        minWidth: 100,
        width: "50%",
        flexShrink: 0,
    },
    drawerPaper: {
        minWidth: 100,
        position: "fixed",
        right: "0",
        height: 'calc(100vh - 40px) !important',
        top:'25px !important',
        bottom:'25px !important',
        borderRadius:'20px 0 0 20px',
        overflowY:'inherit !important',
        maxHeight:'max-content',
    }
}));

export default function RightPopUpForm({id, buttonLabel, buttonIcon, classes, styles}) {
    const classesDrawer = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };


    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor} className="my-20">
                    <button
                        id={id}
                        className={classes}
                        style={styles}
                        onClick={toggleDrawer(anchor, true)}>{buttonIcon} {buttonLabel}
                    </button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        classes={{
                            paper: classesDrawer.drawerPaper
                        }}
                    >

                        <div className="py-12 px-5 sm:px-24 overflow-y-auto">
                            <button className="close-popup-btn" onClick={toggleDrawer(anchor, false)}/>
                            <div className="sm:max-w-screen-md w-full mbn-10">
                                <h2 className="font-proxima-nova-bold uppercase text-2xl mb-4">Une question à poser
                                    ?</h2>
                                <div className="max-w-screen-sm	mb-4">
                                    <p className="font-proxima-nova-regular text-base mb-10">
                                        Laissez vos informations pour planifier une démonstration avec un expert
                                        Ashnoo.
                                        Nous vous montrerons les fonctionnalités importantes pour vous et répondrons
                                        à
                                        vos
                                        questions.
                                    </p>
                                </div>
                            </div>
                            <ContactForm/>
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
