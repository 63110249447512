import React from 'react';

function FaqHeading() {
    return (
        <div className="text-white">
            <h1 className="font-proxima-nova-bold sm:text-4xl text-3xl uppercase">Vous vous posez <br/>
                encore des questions ?</h1>
            <p className="text-lg font-proxima-nova-regular my-4">
                Nous vous recommandons vivement de nous contacter pour <br/> répondre à toutes vos questions
            </p>
        </div>
    );
}

export default FaqHeading;