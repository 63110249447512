import React from 'react';
import FaqHeading from "./FaqHeading";
import FaqAccordion from "./FaqAccordion";

function FaqSection() {
    return (
        <div className="bg-blue-15155c sm:p-10 sm:p-4 p-5">
            <div className="sm:max-w-screen-xl w-full mx-auto grid lg:grid-cols-2 grid-cols-1 items-center gap-4">
                <FaqHeading/>
                <FaqAccordion/>
            </div>
        </div>
    );
}

export default FaqSection;