import React from 'react';
import {Formik} from 'formik';
import * as Yup from "yup";
import {getUrlParams, sendNewsletter} from "../../services/mainSrvices";
import {toast} from "react-toastify";
import {sendGoogleTagManagerUserEvent} from "../../services/tracking";

const NewsletterForm = ({buttonLabel}) => (
    <Formik
        initialValues={{email: '', project: process.env.REACT_APP_PROJECT,}}
        validationSchema={Yup.object({
            email: Yup.string().email('Adresse e-mail invalide').required('champs obligatoires'),
        })}
        onSubmit={async (email, {setSubmitting, resetForm}) => {
            const utm = getUrlParams();
            const newsletter = Object.assign({}, email, utm);
            try {
                const response = await sendNewsletter(newsletter);
                if (response !== null && response.status === 201) {
                    setSubmitting(false);
                    resetForm();
                    toast.success(`Votre demande a bien été envoyée, un expert Ashnoo prendra contact avec vous dans les plus brefs délais`);
                    sendGoogleTagManagerUserEvent('newSubscriber', 'newsletter', email);
                }
            } catch (exception) {
                toast.error(exception.response.data.message);
                setSubmitting(true);
            }
        }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
          }) => (
            <form className="flex justify-between lg:flex-wrap xl:flex-nowrap items-center relative"
                  onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    className="flex-initial sm:w-80 w-full py-6 bg-transparent px-4"
                    placeholder="Votre adresse professionnelle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                />
                <span
                    className="absolute left-0 bottom-1 ml-5 text-red-500 uppercase font-proxima-nova-regular text-xs">{errors.email && touched.email && errors.email}</span>
                <button id="newsletter__btn" className="uppercase text-black bg-white py-6 px-10" type="submit"
                        disabled={isSubmitting}>
                    {buttonLabel}
                </button>
            </form>
        )}
    </Formik>
);

export default NewsletterForm;
