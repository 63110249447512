
function HeroSectionContent({heroSection}) {
    return (
        <div className="hero-section-content-container sm:max-w-screen-xl w-full sm:px-0 px-5 mx-auto">
            <p className="heading">
                <span>{heroSection.title}</span>
                <div className="flex lg:flex-row flex-col items-center-justify-center gap-10 my-5">
                    <img className="sm:w-54" src={heroSection.mainTitleImage.src}  alt={heroSection.mainTitleImage.alt}/>
                    <img className="sm:w-48 w-24 mx-auto" src={heroSection.secondTitleImage.src}  alt={heroSection.secondTitleImage.alt}/>
                </div>
                <span>{heroSection.subtitle}</span>
            </p>
            <div>
                <p className="text">
                    {heroSection.description}
                </p>
            </div>
        </div>
    );
}

export default HeroSectionContent;