export const getIndustries = [
    {
        "name": "Administration publique"
    },
    {
        "name": "Aéronautique et aérospatiale"
    },
    {
        "name": "Agriculture"
    },
    {
        "name": "Agro-alimentaire"
    },
    {
        "name": "Architecture et urbanisme"
    },
    {
        "name": "Armée"
    },
    {
        "name": "Articles de luxe et bijouterie"
    },
    {
        "name": "Articles de sport"
    },
    {
        "name": "Arts"
    },
    {
        "name": "Arts et artisanat"
    },
    {
        "name": "Assurances"
    },
    {
        "name": "Banques"
    },
    {
        "name": "Chimie"
    },
    {
        "name": "Compagnie aérienne/Aviation"
    },
    {
        "name": "Construction"
    },
    {
        "name": "Cosmétiques"
    },
    {
        "name": "Divertissements"
    },
    {
        "name": "Enseignement supérieur"
    },
    {
        "name": "Environnement et énergies renouvelables"
    },
    {
        "name": "Équipements et services de loisirs"
    },
    {
        "name": "Équipements médicaux"
    },
    {
        "name": "Formation professionnelle et coaching"
    },
    {
        "name": "Génie civil"
    },
    {
        "name": "Hôtellerie et hébergement"
    },
    {
        "name": "Immobilier"
    },
    {
        "name": "Industrie automobile"
    },
    {
        "name": "Industrie du cinéma"
    },
    {
        "name": "Industrie pharmaceutique"
    },
    {
        "name": "Internet"
    },
    {
        "name": "Logiciels informatiques"
    },
    {
        "name": "Logistique et chaîne d’approvisionnement"
    },
    {
        "name": "Loisirs"
    },
    {
        "name": "Marketing et publicité"
    },
    {
        "name": "Matériaux de construction"
    },
    {
        "name": "Matériel informatique"
    },
    {
        "name": "Médias en ligne"
    },
    {
        "name": "Médias radio et télédiffusés"
    },
    {
        "name": "Messageries et fret"
    },
    {
        "name": "Meubles"
    },
    {
        "name": "Mines et métaux"
    },
    {
        "name": "Musées et institutions culturelles"
    },
    {
        "name": "Musique"
    },
    {
        "name": "Organisation d’événements"
    },
    {
        "name": "Parti politique"
    },
    {
        "name": "Pêche"
    },
    {
        "name": "Pétrole et énergie"
    },
    {
        "name": "Photographie"
    },
    {
        "name": "Presse écrite"
    },
    {
        "name": "Production audiovisuelle"
    },
    {
        "name": "Produits électroniques grand public"
    },
    {
        "name": "Recrutement"
    },
    {
        "name": "Relations publiques et communication"
    },
    {
        "name": "Restaurants"
    },
    {
        "name": "Santé"
    },
    {
        "name": "Secteur laitier"
    },
    {
        "name": "Secteur médico-psychologique"
    },
    {
        "name": "Sports"
    },
    {
        "name": "Télécommunications"
    }
];