import React from 'react';

function GetIntouchHeading({title, subtitle}) {
    return (
        <div className="text-white">
            <h1 className="font-proxima-nova-regular text-2xl sm:w-3/4 w-full">{title}</h1>
            <h2 className="font-proxima-nova-bold text-2xl">{subtitle}</h2>
        </div>
    );
}

export default GetIntouchHeading;