import parse from "html-react-parser";
import NewsletterForm from "./NewsletterForm";

function Newsletter({newsletter}) {
    return (
        <div className="mx-auto text-white bg-black py-20 flex lg:flex-row sm:flex-col flex-wrap px-5">
              <div className="mx-auto flex sm:flex-row sm:flex-wrap sm:justify-center flex-col items-center gap-20 justify-between">
                  <p className="text-white text-4xl uppercase font-proxima-nova-regular sm:max-w-screen-sm max-w-max">{parse(newsletter.title)}</p>
                  <div className="flex items-center justify-start border-2 border-white font-proxima-nova-bold">
                      <NewsletterForm buttonLabel={newsletter.buttonLabel}/>
                  </div>
              </div>
        </div>
    );
}

export default Newsletter;