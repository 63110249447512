import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_NETLIFY_FUNCTIONS_HOST,
    headers: {
        'content-type': 'application/ld+json',
    },
    //validateStatus: status => (status >= 200 && status < 300) || status === 422 || status === 400
});

export const sendNewLead = async (person) => {
    return axiosInstance({
        method: 'post',
        url: '/.netlify/functions/lead',
        data: person
    });
}

export const sendNewsletter = async (email) => {
    return axiosInstance({
        method: 'post',
        url: '/.netlify/functions/newsletter',
        data: email
    });
}

export const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');

    return {utm_source, utm_medium, utm_campaign};
}
