import React from 'react';
import parse from "html-react-parser";

function HeroVideoSection({heroVideoSection}) {
    return (
        <div className="sm:max-w-screen-lg w-full sm:px-0 px-5 mx-auto my-6">
            <div className="mx-auto text-center mb-5 sm:py-5 py-6">
                <h1 className="sm:text-4xl text-2xl uppercase text-white px-4">{parse(heroVideoSection.heading.title)}</h1>
                <h3 className="font-proxima-nova-regular py-3 text-2xl">{heroVideoSection.heading.subtitle}</h3>
            </div>
            <div className="resp-container px-5">
                <iframe title="hero video" width="1129" height="610" className="resp-iframe"
                        src={heroVideoSection.iframeSrc}
                        allow="encrypted-media" allowFullScreen/>
            </div>
        </div>
    );
}

export default HeroVideoSection;