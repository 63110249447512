import parse from "html-react-parser";

function VideosSection({videosSection}) {
    return (
        <div className="sm:max-w-screen-lg py-10 sm:px-0 px-5 text-white font-sans mx-auto block text-center">
            <div>
                <p className="sm:text-5xl text-2xl uppercase my-6 whitespace-pre-line">
                    {parse(videosSection.title)}
                </p>
                <p className="text-lg text-white font-proxima-nova-regular mb-5">
                    {videosSection.subTitle}
                </p>
                <div
                    className="mx-auto flex items-center justify-center text-center flex-wrap lg:flex-nowrap gap-6 py-10">
                    {videosSection.iframes.map((iframe, index) =>
                        <div className="max-w-screen-md" key={index}>
                            <iframe title="backstage video section"
                                    src={iframe.src}
                                    allow="encrypted-media"
                                    allowFullScreen
                                    height={293}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VideosSection;