import RightPopUpForm from "../mui/utils/Drawer";
import headerBtnBg from "../../assets/images/ashnoo/header-btn-bg.svg";

function Header({heroSection}) {
    return (
        <div className="sm:max-w-screen-xl w-full px-5 mx-auto bg-transparent relative">
            <div className="header-flex">
                <img className="w-full max-w-[211px]" src={heroSection.brandLogo} alt={heroSection.altLogo}/>
                <RightPopUpForm
                    id="header__btn"
                    classes="btn bg-transparent text-blue-17173f text-lg pb-[32px] font-proxima-bold"
                    buttonLabel="programmer un rdv"
                    styles={{background: `url(${headerBtnBg}) no-repeat`, width: 369, height: 96}}
                />
            </div>
        </div>
    );
}

export default Header;
