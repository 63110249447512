import TagManager from "react-gtm-module";


export const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

export const initGoogleTagManager = () => {
    TagManager.initialize(tagManagerArgs)
}

export const sendGoogleTagManagerUserEvent = (event, category, value) => {
    TagManager.dataLayer({
        dataLayer: {
            event,
            category,
            value,
        },
    });
}
