import TabsContainer from "./TabsContainer";

function PackagesSection() {
    return (
        <div className="bg-white">
            <TabsContainer/>
        </div>
    );
}

export default PackagesSection;