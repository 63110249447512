
function PackagesHeading() {
    return (
        <>
            <h1 className="font-proxima-nova-bold text-center text-6xl uppercase text-black py-20">
                ce que<span className="text-primary-purple"> nos package </span>
                inclues?</h1>
        </>
    );
}

export default PackagesHeading;