import packages from '../../data/packages.json';
import features from '../../data/features.json';
import {getIcons} from '../../services/getIcons';

// const pack = packages.filter(p => p.id === 2)[0];

function PackagesCardSection({pack}) {
    if (pack === null) {
        pack = packages.filter(p => p.id === 1)[0];
    }
    return (
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 container xl:px-32 px-4">
            {features.map((p, index) => {
                    return <div key={index} className={`${!pack.features.includes(p.id) && 'opacity-75'} tab-card`}>
                        <div className="flex items-baseline justify-between">
                            <p className="font-proxima-nova-bold text-black text-2xl">{p.title}</p>
                            <img src={getIcons[index]} alt={getIcons.name}/>
                        </div>
                        <p className="font-proxima-nova-regular text-black text-base my-5">
                            {p.description}
                        </p>
                    </div>
                }
            )}
        </div>
    );
}

export default PackagesCardSection;
