import packages from '../../data/packages.json';

function PackagesTabSection({setPack, pack}) {
    if (pack === null) {
        pack = packages.filter(p => p.id === 1)[0];
    }
    return (
        <div
            className="flex sm:items-center items-start xl:justify-center justify-start max-w-screen-sm mx-auto overflow-auto">
            {packages.map((p, index) =>
                <button key={index} onClick={() => setPack(p)}
                        className={`tab-btn ${pack.id === p.id ? 'tab-active-btn' : 'tab-inactive-btn'}`}>
                    {p.name}
                </button>
            )}
        </div>
    );
}

export default PackagesTabSection;