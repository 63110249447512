import React from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {getUrlParams, sendNewLead} from "../../services/mainSrvices";
import {toast} from 'react-toastify';
import {getIndustries} from "../../services/getIndustries";
import {sendGoogleTagManagerUserEvent} from "../../services/tracking";

export const ContactForm = () => {
    return (
        <Formik
            initialValues={{
                project: process.env.REACT_APP_PROJECT,
                gender: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                company: '',
                job: '',
                industry: 'Immobilier',
                // acceptTerms: false,
            }}
            validationSchema={Yup.object({
                gender: Yup.string().required('champs obligatoires'),
                firstName: Yup.string()
                    .required('champs obligatoires'),
                lastName: Yup.string()
                    .required('champs obligatoires'),
                email: Yup.string().email('Adresse e-mail invalide').required('champs obligatoires'),
                phone: Yup.string().min(10, 'numéro de téléphone invalide').max(15, 'numéro de téléphone invalide').required('champs obligatoires'),
                company: Yup.string().required('champs obligatoires'),
                job: Yup.string().required('champs obligatoires'),
                industry: Yup.string().required('champs obligatoires'),
                // acceptTerms: Yup.bool().oneOf([true], 'Le champ doit être coché'),
            })}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
                const utm = getUrlParams();
                const person = Object.assign({}, values, utm);
                try {
                    const response = await sendNewLead(person);
                    if (response !== null && response.status === 201) {
                        setSubmitting(false);
                        resetForm();
                        toast.success(`Votre demande a bien été envoyée, un expert Ashnoo prendra contact avec vous dans les plus brefs délais`);
                        sendGoogleTagManagerUserEvent('newLead', 'leadsGeneration', values)
                    }
                } catch (exception) {
                    toast.error(exception.response.data.message);
                    setSubmitting(true);
                }
            }}
        >
            {({isValid, errors, touched}) => (
                <Form>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="gender">Civilité*</label>
                            <Field name="gender" as="select"
                                   className={`form-group ${errors.gender && touched.gender && 'border-red-600'}`}>
                                <option value=""/>
                                <option value="mr.">Mr.</option>
                                <option value="mme.">Mme.</option>
                                <option value="miss.">Miss.</option>
                            </Field>
                            <span className="error-input">
                         <ErrorMessage name="gender" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>
                        <div/>
                    </div>

                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="firstName">Prénom*</label>
                            <Field name="firstName" type="text"
                                   className={`form-group ${errors.firstName && touched.firstName && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="firstName" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>

                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="lastName">Nom*</label>
                            <Field name="lastName" type="text"
                                   className={`form-group ${errors.lastName && touched.lastName && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="lastName" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>


                    </div>

                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="email">E-mail*</label>
                            <Field name="email" type="email"
                                   className={`form-group ${errors.email && touched.email && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="email" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>

                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="phone">Téléphone*</label>
                            <Field name="phone" type="text"
                                   className={`form-group ${errors.phone && touched.phone && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="phone" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>


                    </div>

                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="company">Entreprise*</label>
                            <Field name="company" type="text"
                                   className={`form-group ${errors.company && touched.company && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="company" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>

                        <div className="flex flex-col mb-4 relative">
                            <label className="font-proxima-nova-bold" htmlFor="job">Fonction* </label>
                            <Field name="job" type="text"
                                   className={`form-group ${errors.job && touched.job && 'border-red-600'}`}/>
                            <span className="error-input">
                       <ErrorMessage name="job" render={msg => <div>{msg}</div>}/>
                        </span>
                        </div>
                    </div>

                    <div className="flex flex-col mb-4 relative">
                        <label className="font-proxima-nova-bold" htmlFor="industry">Secteur*</label>
                        <Field name="industry" as="select"
                               className={`form-group w-full ${errors.industry && touched.industry && 'border-red-600'}`}>
                            {getIndustries.map((industry, index) =>
                                <option value={industry.name} key={index}>{industry.name}</option>
                            )}
                        </Field>
                        <span className="error-input">
                       <ErrorMessage name="industry" render={msg => <div>{msg}</div>}/>
                </span>
                    </div>
                    {/*<div className="mb-10 relative">*/}
                    {/*    <Field type="checkbox" name="acceptTerms"/>*/}
                    {/*    <span className="font-proxima-nova-regular text-sm"> J’accepte les <a href="/">Conditions Générales de Leadz</a> et je*/}
                    {/*reconnais avoir été informé(e) et consentir au traitement de mes données personnelles détaillées*/}
                    {/*dans la <a href="/">Politique de confidentialité de Leadz.*</a></span>*/}
                    {/*    <p className="error-input left-0">*/}
                    {/*        <ErrorMessage name="acceptTerms" render={msg => <div>{msg}</div>}/>*/}
                    {/*    </p>*/}
                    {/*</div>*/}


                    {/*<Alert className="my-5" severity="error">*/}

                    {/*</Alert>*/}

                    <button
                        className={`my-5 bg-black text-white rounded-lg block w-full p-5 font-proxima-nova-bold uppercase text-3xl text-center ${!isValid && 'opacity-75 cursor-not-allowed'}}`}
                        type="submit"> demander une demo
                    </button>
                    <div className="block mx-auto text-center">
                        <small className="text-center font-proxima-nova-bold text-sm">Les informations suivies d’un astérisque (*) sont obligatoires.</small>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
