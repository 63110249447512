import React from 'react';
import RightPopUpForm from "../mui/utils/Drawer";
import parse from "html-react-parser";

function MarketingEssentialsSection({marketingEssentialsSection}) {
    return (
        <div className="bg-cover w-full relative"
             style={{background: `#3b4369 no-repeat left center`, height: 500}}>
            <div
                className="container lg:py-24 lg:px-36 px-4 z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="divider divider-border-yellow-c1f84f sm-divider-margin"/>
                <div className="border-l-4 border-r-4 border-yellow-c1f84f pb-20 pt-10">
                    <h1 className=" sm:text-4xl text-2xl text-center text-yellow-c1f84f font-proxima-nova-bold uppercase py-8 sm:px-0 px-4 sm:-mt-0 -mt-4">
                        {marketingEssentialsSection.title}
                    </h1>
                    <p className="font-proxima-nova-regular text-yellow-c1f84f text-center">
                        {marketingEssentialsSection.subtitle}
                    </p>
                </div>
                <div className="divider divider-border-yellow-c1f84f lg-divider-margin">
                    {/*<button*/}
                    {/*    className="bg-yellow-c1f84f text-blue-1a1a73 sm:py-8 py-4 sm:px-24 px-4 uppercase sm:text-4xl text-2xl whitespace-nowrap">*/}
                    {/*    {marketingEssentialsSection.buttonIcon} {marketingEssentialsSection.buttonLabel}*/}
                    {/*</button>*/}
                    <RightPopUpForm
id="marketing__essentials__btn"
                        classes="bg-yellow-c1f84f text-blue-1a1a73 sm:py-8 py-4 sm:px-24 px-4 uppercase sm:text-4xl text-2xl whitespace-nowrap"
                        buttonLabel={parse(`${marketingEssentialsSection.buttonLabel}`)}/>
                </div>
            </div>
        </div>
    );
}

export default MarketingEssentialsSection;