import parse from "html-react-parser";
import RightPopUpForm from "../mui/utils/Drawer";

function HeroSectionBanner({heroSection}) {
    return (
        <div className="max-w-screen-lg mx-auto">
            <RightPopUpForm
                id="hero__section__btn"
                classes="font-sans font-bold sm:text-4xl text-2xl uppercase bg-blue-17173f w-full py-4 flex sm:flex-row-reverse flex-col justify-center items-center gap-6 rounded"
                buttonLabel={parse(`${heroSection.button.label}`)}/>
        </div>
    );
}

export default HeroSectionBanner;