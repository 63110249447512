import Header from "../header/Header";
import HeroSectionContent from "./heroSectionContent";
import HeroSectionBanner from "./HeroSectionBanner";

function HeroSection({heroSection}) {
    return (
        <div className="pb-14" style={{
            background: `url(${heroSection.heroImage})`,
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat'
        }}>
            <Header heroSection={heroSection} />
            <HeroSectionContent heroSection={heroSection}/>
            <HeroSectionBanner heroSection={heroSection}/>
        </div>
    );
}

export default HeroSection;